import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import {
  FormControl,
  Select,
  TextField,
  Chip,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Alert,
  TableFooter,
  Pagination,
  Tooltip,
  Typography,
  OutlinedInput,
  IconButton,
} from "@mui/material";

import ExpandMoreSharpIcon from "@mui/icons-material/ExpandMoreSharp";
import "react-datetime-range-super-picker/dist/index.css";
import { DNA } from "react-loader-spinner";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { Box } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import data from "../../utils/TransactionsMap";
import TransactionDetails from "../../components/Elements/Transactions/TransactionDetails";
import CaretDown from "components/Icons/CaretDown";
import XCircleIcon from "components/Icons/XCircle";
import SaveIcon from "components/Icons/FloppyDisk";
import Breadcrumb from "components/Breadcrumb/Breadcrumb";
import {
  URL,
  MERCHANT_ID,
  PAYMENT_TRANSACTIONS_ENDPOINT,
} from "constants/Constants";
import convertFromDate from "utils/ConvertDateToString";
import {
  convertFromTime,
  convertToTime,
  convertToDate,
} from "utils/ConvertDateToString";
import "./Transactions.css";
import paymentTypeIcons from "utils/PaymentTypeIcons";
import processorIcons from "utils/ProcessorIcons";
import { httpClient } from "utils/HttpClient";
import { StyledTableCell, StyledTableRow } from "utils/Styles";
import Logout from "Logout";
import Notepad from "components/Icons/Notepad";
import Search from "components/Icons/Search";
import FunnelSimple from "components/Icons/FunnelSimple";
import ExpandUpdownFill from "components/Icons/ExpandUpdownFill";
import DotsThreeVertical from "components/Icons/DotsThreeVertical";
import { Dropdown } from "@mui/base/Dropdown";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import FileText from "components/Icons/FileText";
import { fil } from "date-fns/locale";
import { CleanHands, Margin } from "@mui/icons-material";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { StaticTimePicker } from "@mui/x-date-pickers/StaticTimePicker";
import { getTime } from "date-fns";
import { FunctionsProvider } from "./FunctionProvider";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useServiceContext } from "constants/ServiceProvider";
import {
  Last7Days,
  Yesterday,
  Today,
  Last30Days,
} from "./ConvertDateToMonthDetails";
import { useFunctions } from "./FunctionProvider";
import dayjs from "dayjs";
import { filter } from "lodash";
// import { ServiceContext } from "constants/ServiceProvider";
// import { useContext } from "react";

function Transactions() {
  const [transactions, setTransactions] = useState([]);
  const [tableHeadings, setTableHeadings] = useState([]);
  let initialState = [];
  const [filters, setFilters] = useState(initialState);
  const [chipDelete, setChipDelete] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [warning, setWarning] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [clickedChipIndex, setClickedChipIndex] = useState(null);
  const allColumns = Object.keys(data.transactionsMap);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedOptionsMap, setSelectedOptionsMap] = useState([]);
  const [checkedColumns, setCheckedColumns] = useState(
    allColumns.filter((columnKey) =>
      data.ListOfShowingColumns.includes(columnKey)
    )
  );

  const [page, setPage] = useState(1);
  const [dropdownFilter, setDropdownFilter] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [clickedLinkId, setClickedLinkId] = useState();
  const [selectedTransactionType, setSelectedTransactionType] = useState();
  const [breadcrumbList, setBreadcrumbList] = React.useState([
    { title: "Transactions", component: <Notepad /> },
  ]);
  const [fromButtonStatus, setFromButtonStatus] = useState(false);
  const [toButtonStatus, setToButtonStatus] = useState(false);
  const [dateLabel, setDateLabel] = useState(false);
  const [merchantReferenceId, setMerchantReferenceId] = useState(false);
  const [amount, setAmount] = useState(false);
  const [processorReconId, setProcessorReconId] = useState(false);
  const [processorId, setProcessorId] = useState(false);
  const [getDate, setGetDate] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const [time, setTime] = useState({
    fromTime: "",
    toTime: "",
  });

  const [date, setDate] = useState([
    {
      fromDate: "",
      toDate: "",
    },
  ]);

  const statusLength = filters?.status?.length;
  const currencyLength = filters?.currency?.length;
  const paymentMethodLength = filters?.paymentMethod?.length;
  const processorCodeLength = filters?.processorCode?.length;
  const transactionTypeLength = filters?.transactionType?.length;

  const initializeTime = () => dayjs().startOf("day");
  const [toTime, setToTime] = useState(initializeTime());

  const handleFromDateChange = (newDate) => {
    setDate((prev) => {
      return { ...prev, fromDate: newDate, fromDateStatus: true };
    });
  };

  const handleFromTimeChange = (newTime, filter) => {
    setTime((prev) => {
      return { ...prev, fromTime: newTime, fromTimeStatus: true };
    });
    handleFilterChange(
      filter?.filterOptions?.queryParamOutbound,
      `${date.fromDate}T${time.fromTime}`
    );
  };

  const handleToDateChange = (newDate) => {
    setDate((prev) => {
      return { ...prev, toDate: newDate, toDateStatus: true };
    });
  };

  const handleToTimeChange = (newTime, filter) => {
    setTime((prev) => {
      return { ...prev, toTime: newTime, toTimeStatus: true };
    });
    handleFilterChange(
      filter?.filterOptions?.queryParamInbound,
      `${date.toDate}T${time.toTime}`
    );
  };

  const handleLinkClick = (id, type) => {
    // console.log(type);
    setSelectedTransactionType(type);
    // console.log(selectedTransactionType, "type in transactions");
    setClickedLinkId(id);
  };

  console.log(filters, "filters");
  console.log(selectedOptionsMap, "map");
  console.log(transactions, "transactions");

  useEffect(() => {
    if (filters?.merchantReferenceId?.length > 0) {
      setMerchantReferenceId(true);
    } else {
      setMerchantReferenceId(false);
    }

    if (filters?.processorReconId?.length > 0) {
      setProcessorReconId(true);
    } else {
      setProcessorReconId(false);
    }

    if (filters?.processorTransactionId?.length > 0) {
      setProcessorId(true);
    } else {
      setProcessorId(false);
    }
  });
  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(1);
  //   fetchTransactionDataWithFilter();
  // };

  // const fromDate = (event) => {
  //   //setDate(event.target.value);
  //   setFilters((previousState) => {
  //     return { ...previousState, fromDate: event.target.value };
  //   });
  // };

  // const toDate = (event) => {
  //   setFilters((previousState) => {
  //     return { ...previousState, toDate: event.target.value };
  //   });
  // };

  // const reloadFilters = () => {
  //   setWarning(false);

  //   fetchTransactionDataWithFilter();
  // };

  const applyfilters = () => {
    console.log("apply");
    handleClose();
  };

  const handleDetailsClose = () => {
    console.log("close");
    setClickedLinkId(null);
    setSelectedTransactionType(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const hasFilters = (filter) =>
    filters[filter.filterOptions.queryParam]?.length ||
    (filters[filter.filterOptions.queryParamInbound]?.length &&
      filters[filter.filterOptions.queryParamOutbound]?.length);

  Object.keys(data.transactionsMap).forEach((key) => {
    const selectedOptions =
      data.transactionsMap[key].filterOptions.selectedOptions;
    initialState = {
      ...initialState,
      [key]: selectedOptions,
    };
  });
  const renderTableCell = (item, columnKey, data) => {
    const { transactionsMap } = data;
    const columnData = transactionsMap[columnKey];
    const path = columnData.path;

    if (path === "paymentMethod" || path === "processorCode") {
      const value = getFirstNonNullValue(item, path);

      return (
        <Tooltip key={value} title={value}>
          {value && (
            <img
              style={{ height: 48, width: 48 }}
              src={paymentTypeIcons[value] || processorIcons[value] || null}
            />
          )}
        </Tooltip>
      );
    } else if (path === "merchantReferenceId") {
      return (
        <Typography>
          <a
            href="#"
            className={`reference-link`}
            onClick={() => handleLinkClick(item.id, item.transactionType)}
          >
            {item.merchantReferenceId}
          </a>
        </Typography>
      );
    } else {
      return getFirstNonNullValue(item, path);
    }
  };

  useEffect(() => {
    console.log("Checking");
    const textFieldId = document.getElementById("checking");
    console.log(textFieldId, "text");
  }, []);

  useEffect(() => {
    fetchTransactionDataWithFilter();
  }, [chipDelete, page]);
  useLayoutEffect(() => {
    fetchTransactionData();
  }, []);
  const fetchTransactionData = () => {
    setPage(1);
    const formData = new URLSearchParams();

    formData.append(
      "transactionType",
      "PAYMENT, CAPTURE, REFUND, CREDIT, CHECKOUT_SESSION"
    );
    console.log(formData, "text");

    const response = httpClient
      .postFormData(
        `${URL}${PAYMENT_TRANSACTIONS_ENDPOINT}?&type=transactions&orderbycol=transactionTime&orderby=DESC&pageNumber=${page}&pageSize=${rowsPerPage}`,

        formData
      )
      .then((response) => {
        // Check if the status code is 200
        if (response.ok) {
          return response.json();
        } else {
          // Check if the status code is 401
          if (response.status === 401) {
            Logout();
          } else {
            setWarning(true);
          }
        }
      })
      .then((responseData) => {
        // console.log(responseData);
        setTransactions(responseData);
        if (responseData.length > 0) {
          // Assuming the first item in the response array contains all table headings
          setTableHeadings(Object.keys(responseData[0]));
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle errors here
      });
  };

  const fetchTransactionDataWithFilter = (pageNumber, pageSize) => {
    //  console.log("in http call ", filters);

    setIsLoading(true);

    var formData = new URLSearchParams({
      ...Object.fromEntries(
        Object.entries(filters)?.filter(([key, value]) => {
          return Array.isArray(value)
            ? value.some((item) => item !== null)
            : value !== null;
        })
      ),
    });

    if (formData.toString().length === 0) {
      // console.log("in length = 0");
      formData = new URLSearchParams();
      formData.append(
        "transactionType",
        "PAYMENT, CAPTURE, REFUND, CREDIT, CHECKOUT_SESSION"
      );
    }
    console.log(formData, "formData");

    httpClient
      .postFormData(
        `${URL}${PAYMENT_TRANSACTIONS_ENDPOINT}?&type=transactions&orderbycol=transactionTime&orderby=DESC&pageNumber=${page}&pageSize=${rowsPerPage}`,
        formData
      )
      .then((response) => {
        // Check if the status code is 200
        if (response.ok) {
          return response.json();
        } else {
          // Check if the status code is 401
          if (response.status === 401) {
            Logout();
          } else {
            setWarning(true);
          }
        }
      })
      .then((responseData) => {
        // console.log(responseData, "page:", page);
        setIsLoading(false);
        setTransactions(responseData);
        if (responseData?.length > 0) {
          // Assuming the first item in the response array contains all table headings
          setTableHeadings(Object.keys(responseData[0]));
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle errors here
      });
  };

  const handleFilterChange = (type, param) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [type]: param,
    }));

    setSelectedOptionsMap((prevFilters) => ({
      ...prevFilters,
      [type]: param,
    }));
  };

  //in Search-bar Table Data Searching

  let searchText = filters?.searchQuery?.toString() || "";

  const filterData = transactions?.filter((each) => {
    // console.log(each, "each");

    let searchAmount = each?.amount?.value?.toString()?.includes(searchText);

    let searchTransactionType = each?.transactionType
      ?.toLowerCase()
      ?.includes(searchText);

    let searchStatus = each?.status.toLowerCase().includes(searchText);

    let searchCurrencyCode = each?.amount?.currencyCode
      ?.toLowerCase()
      .includes(searchText);

    let searchMerchantReferenceId = each?.merchantReferenceId
      ?.toLowerCase()
      ?.includes(searchText);
    let searchId = each?.id?.toLowerCase()?.includes(searchText);

    let searchDate = each?.expiresAt?.toLowerCase()?.includes(searchText);
    let searchProcessorId = each?.processorReconId?.includes(searchText);
    let searchProcessorTransactionId =
      each?.processorTransactionId?.includes(searchText);

    return searchText === ""
      ? each
      : searchAmount ||
          searchTransactionType ||
          searchStatus ||
          searchCurrencyCode ||
          searchMerchantReferenceId ||
          searchId ||
          searchProcessorId ||
          searchProcessorTransactionId ||
          searchDate;
  });

  const reloadFilters = () => {
    setWarning(false);
    fetchTransactionDataWithFilter();
  };

  const resetFilters = (filter, queryParamInbound, queryParamOutbound) => {
    setChipDelete((prevValue) => !prevValue);

    if (filter) {
      // Reset specific filter

      setFilters((prevFilters) => ({
        ...prevFilters,
        [filter]: Array.isArray(filter)
          ? []
          : typeof filter === "string"
          ? null
          : { [queryParamInbound]: null, [queryParamOutbound]: null },
      }));
      if (Array.isArray(filters[filter])) {
        setSelectedOptionsMap((prevSelectedOptionsMap) => ({
          ...prevSelectedOptionsMap,
          [filter]: [],
        }));
      }
      console.log("filters ", filters);
    } else {
      // Reset all filters
      setPage(1);
      setFilters([]);
      applyfilters();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setClickedChipIndex(null);
  };

  const handleChipDelete = (
    queryParam,
    queryParamInbound,
    queryParamOutbound
  ) => {
    resetFilters(queryParam, queryParamInbound, queryParamOutbound);

    handleClose();
  };

  const handleCheckboxChange = (columnKey) => (event) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setCheckedColumns([...checkedColumns, columnKey]);
      if (!data.ListOfShowingColumns.includes(columnKey)) {
        data.ListOfShowingColumns.push(columnKey);
      }
      data.ListOfShowingColumns.sort(
        (a, b) => data.transactionsMap[a].order - data.transactionsMap[b].order
      );
    } else {
      setCheckedColumns(checkedColumns.filter((col) => col !== columnKey));
      data.ListOfShowingColumns = data.ListOfShowingColumns.filter(
        (col) => col !== columnKey
      );
      data.ListOfShowingColumns.sort(
        (a, b) => data.transactionsMap[a].order - data.transactionsMap[b].order
      );
    }
  };

  const handleFilterCheckboxChange = (filterParam, option) => {
    setSelectedOptionsMap((prevSelectedOptionsMap) => {
      const selectedOptions = prevSelectedOptionsMap[filterParam] || [];
      const updatedOptions = selectedOptions.includes(option)
        ? selectedOptions.filter((opt) => opt !== option)
        : [...selectedOptions, option];
      handleFilterChange(filterParam, updatedOptions);
      return {
        ...prevSelectedOptionsMap,
        [filterParam]: updatedOptions,
      };
    });
  };

  // Select All Option
  const handleSelectAllOptions = (param, optionsList) => {
    let selectedData;
    Object.keys(data.transactionsMap).filter((paramData) => {
      if (data.transactionsMap[paramData].path === param) {
        selectedData = paramData;
      } else if (data.transactionsMap[paramData].path.slice(7, 15) === param) {
        selectedData = paramData;
      }
    });

    const selectedParamOptionsLength =
      data.transactionsMap[selectedData]?.filterOptions.options.length;
    if (filters[param]?.length === selectedParamOptionsLength) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [param]: "",
      }));
      setSelectedOptionsMap((prevFilters) => ({
        ...prevFilters,
        [param]: "",
      }));
    } else {
      handleFilterChange(param, optionsList);
    }
  };

  const clickFilters = () => {
    setDropdownFilter(!dropdownFilter);
  };

  return (
    <FunctionsProvider>
      <div className="payment-transaction-container">
        {!clickedLinkId ? (
          <>
            <Box className="transaction-header">
              <div className="breadcrumb-containers">
                <Breadcrumb list={breadcrumbList} />
              </div>
              <Typography className="transaction-text">Transactions</Typography>
              {/* <TextField  /> */}
              <Typography className="description-text">
                Initiate, track and manage all your transactions at one place.
              </Typography>
              <Box className="transaction-row-container">
                <Box className="filter-frame-container">
                  {Object.values(data.transactionsMap).map((filter, index) => {
                    return (
                      filter.filterOptions.type === "DATE" && (
                        <Box
                          key={filter.displayName}
                          className="each-filter-frame"
                        >
                          <Box className="drop-down-container">
                            <div
                              className="click-dropdown"
                              onClick={(event) =>
                                setAnchorEl(
                                  { index, currentTarget: event.currentTarget },
                                  setClickedChipIndex(index),
                                  setSearchTerm("")
                                )
                              }
                              onDelete={
                                hasFilters
                                  ? () =>
                                      handleChipDelete(
                                        filter.filterOptions.queryParam,
                                        filter.filterOptions.queryParamInbound,
                                        filter.filterOptions.queryParamOutbound
                                      )
                                  : null
                              }
                            >
                              <Typography className="placehoder-text">
                                {filter.displayName === "Date"
                                  ? getDate
                                    ? filters.transactionTime_from?.slice(0, 10)
                                    : `${filter.displayName}`
                                  : filter.displayName === "Date" &&
                                    `${filter.displayName}`}
                              </Typography>

                              <CaretDown className="cart-down-icon" />
                            </div>
                          </Box>

                          <Menu
                            className="pop-up-alignment"
                            anchorEl={
                              anchorEl && anchorEl.index === index
                                ? anchorEl.currentTarget
                                : null
                            }
                            open={Boolean(anchorEl && anchorEl.index === index)}
                            onClose={() => {
                              setAnchorEl(null);
                              setClickedChipIndex(null);
                              setFromButtonStatus(false);
                              setToButtonStatus(false);
                              setDate((prev) => ({
                                ...prev,
                                fromDate: "",
                                toDate: "",
                              }));
                              setTime((prev) => ({
                                ...prev,
                                fromTime: "",
                                toTime: "",
                              }));
                            }}
                          >
                            {filter.filterOptions.type === "DATE" && (
                              <div style={{ padding: "0px 10px" }}>
                                <FunctionsProvider>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      gap: 2,
                                    }}
                                  >
                                    <Today
                                      getDate={setGetDate}
                                      filter={filter}
                                      handleFilterChange={handleFilterChange}
                                    />
                                    <Yesterday
                                      getDate={setGetDate}
                                      filter={filter}
                                      handleFilterChange={handleFilterChange}
                                    />
                                    <Last7Days
                                      getDate={setGetDate}
                                      filter={filter}
                                      handleFilterChange={handleFilterChange}
                                    />
                                    <Last30Days
                                      getDate={setGetDate}
                                      filter={filter}
                                      handleFilterChange={handleFilterChange}
                                    />
                                  </Box>
                                </FunctionsProvider>
                                <div style={{ display: "flex" }}>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <DemoContainer
                                      components={["DatePicker", "TimeClock"]}
                                    >
                                      <Box
                                        sx={{ display: "flex", gap: "10px" }}
                                      >
                                        <DemoItem>
                                          <h3 style={{ fontSize: "15px" }}>
                                            From Date
                                          </h3>
                                          <DatePicker
                                            sx={{
                                              height: "40px",
                                              "& .MuiInputBase-root": {
                                                height: "100%",
                                              },
                                            }}
                                            onChange={(newFromDate) =>
                                              handleFromDateChange(
                                                convertFromDate(newFromDate.$d)
                                              )
                                            }
                                            renderInput={(params) => (
                                              <TextField {...params} />
                                            )}
                                          />

                                          <DemoItem>
                                            <StaticTimePicker
                                              onChange={(time) => {
                                                handleFromTimeChange(
                                                  convertFromTime(time),
                                                  filter
                                                );
                                              }}
                                              value={toTime}
                                            />
                                          </DemoItem>
                                        </DemoItem>
                                        <DemoItem>
                                          <h3 style={{ fontSize: "15px" }}>
                                            To Date
                                          </h3>
                                          <DatePicker
                                            sx={{
                                              height: "40px",
                                              "& .MuiInputBase-root": {
                                                height: "100%",
                                              },
                                            }}
                                            onChange={(newFromDate) =>
                                              handleToDateChange(
                                                convertToDate(newFromDate.$d)
                                              )
                                            }
                                            renderInput={(params) => (
                                              <TextField {...params} />
                                            )}
                                          />

                                          <DemoItem>
                                            <StaticTimePicker
                                              onChange={(time) => {
                                                handleToTimeChange(
                                                  convertToTime(time),
                                                  filter
                                                );
                                              }}
                                              value={toTime}
                                            />
                                          </DemoItem>
                                        </DemoItem>
                                      </Box>
                                    </DemoContainer>
                                  </LocalizationProvider>
                                </div>
                              </div>
                            )}

                            {filter.filterOptions.type !== "DATE" && (
                              <MenuItem className="drop-down-menu-item">
                                {/* Render the specific UI for other types of filters */}
                                <TextField
                                  size="small"
                                  fullWidth
                                  label={filter.displayName}
                                  placeholder={`Enter ${filter.displayName}`}
                                  value={
                                    filters[filter.filterOptions.queryParam]
                                  }
                                  onChange={(e) =>
                                    handleFilterChange(
                                      filter.filterOptions.queryParam,
                                      e.target.value
                                    )
                                  }
                                />
                              </MenuItem>
                            )}
                            <MenuItem
                              sx={{
                                display: "flex",
                                gap: "10px",
                                justifyContent: "space-between",
                              }}
                            >
                              <Button
                                size="small"
                                variant="outlined"
                                color="inherit"
                                onClick={() => {
                                  resetFilters(filter.filterOptions.queryParam);
                                  // resetData();
                                  setFromButtonStatus(false);
                                  setToButtonStatus(false);
                                  setDate((prev) => ({
                                    ...prev,
                                    fromDate: "",
                                    toDate: "",
                                  }));
                                  setTime((prev) => ({
                                    ...prev,
                                    fromTime: "",
                                    toTime: "",
                                  }));
                                  setFilters([]);
                                  filter.displayName ===
                                    "Merchant Reference Id" &&
                                    setMerchantReferenceId(false);
                                  filter.displayName === "Amount" &&
                                    setAmount(false);
                                }}
                              >
                                Cancel
                              </Button>
                              <Button
                                size="small"
                                variant="outlined"
                                color="inherit"
                                onClick={() => {
                                  applyfilters(filter);
                                  setFromButtonStatus(false);
                                  setToButtonStatus(false);

                                  setDate((prev) => ({
                                    ...prev,
                                    fromDate: "",
                                    toDate: "",
                                  }));
                                  setTime((prev) => ({
                                    ...prev,
                                    fromTime: "",
                                    toTime: "",
                                  }));
                                  reloadFilters();

                                  filter.displayName ===
                                    "Merchant Reference Id" &&
                                    setMerchantReferenceId(true);
                                  filter.displayName === "Amount" &&
                                    setAmount(true);
                                }}
                              >
                                Ok
                              </Button>
                            </MenuItem>
                          </Menu>
                        </Box>
                      )
                    );
                  })}
                </Box>

                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  {/* <Box className="search-container">
                    <Search />
                    <OutlinedInput
                      size="small"
                      placeholder="Search"
                      type="search"
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                      value={filters.searchQuery}
                      onChange={(e) => {
                        handleFilterChange("text", e.target.value);
                        setSearchValue(e.target.value);
                      }}
                    />
                  </Box> */}

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <Tooltip title={dropdownFilter ? "Close" : "Open"}>
                      <Box className="filter-button" onClick={clickFilters}>
                        <FunnelSimple />
                        <Typography sx={{ color: "white", fontSize: "15px" }}>
                          Filters
                        </Typography>
                        <ExpandMoreSharpIcon style={{ color: "white" }} />
                      </Box>
                    </Tooltip>
                    <Tooltip title="Reload">
                      <Button
                        className="reload-button"
                        size="small"
                        variant="outlined"
                        onClick={reloadFilters}
                      >
                        <RestartAltIcon></RestartAltIcon>Reload
                      </Button>
                    </Tooltip>
                  </Box>
                </div>
              </Box>
            </Box>

            {dropdownFilter && (
              <div className="filter-container-details">
                <Typography className="filters-text">Filters</Typography>
                <Box className="filter-frame-container">
                  {Object.values(data.transactionsMap).map((filter, index) => {
                    const paymentTypePopupHeight =
                      filter.displayName === "Payment Type"
                        ? "payment-type-popup-height"
                        : "drop-down-menu-item";

                    return filter.filtered &&
                      filter.filterOptions.type === "STRING" ? (
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography
                          sx={{ display: "flex", alignItems: "flex-start" }}
                        >
                          {filter.displayName === "Merchant Reference Id" && (
                            <span
                              className={
                                merchantReferenceId
                                  ? "filter-label-text-bold"
                                  : "filter-label-text"
                              }
                            >
                              {filter.displayName}
                            </span>
                          )}{" "}
                          {filter.displayName === "Processor Recon Id" && (
                            <span
                              className={
                                processorReconId
                                  ? "filter-label-text-bold"
                                  : "filter-label-text"
                              }
                            >
                              {filter.displayName}
                            </span>
                          )}
                          {filter.displayName === "Processor Id" && (
                            <span
                              className={
                                processorId
                                  ? "filter-label-text-bold"
                                  : "filter-label-text"
                              }
                            >
                              {filter.displayName}
                            </span>
                          )}
                        </Typography>
                        <Box
                          key={filter.displayName}
                          className="each-filter-frame"
                        >
                          <TextField
                            sx={{
                              borderRadius: "9px",
                              "& .MuiOutlinedInput-root": {
                                borderRadius: "9px",
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "#cacfd8",
                                },
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                  {
                                    borderColor: "#cacfd8",
                                  },
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                color: " #717784",
                                fontSize: "14px",
                                fontFamily: "Inter",
                                fontWeight: "400",
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                  {
                                    color: " #717784",
                                  },
                              },
                            }}
                            InputProps={{
                              sx: {
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "#cacfd8",
                                  borderRadius: "9px",
                                },
                              },
                            }}
                            size="small"
                            fullWidth
                            label={filter.displayName}
                            placeholder={`Enter ${filter.displayName}`}
                            value={filters[filter.filterOptions.queryParam]}
                            onChange={(e) =>
                              handleFilterChange(
                                filter.filterOptions.queryParam,
                                e.target.value
                              )
                            }
                          />
                        </Box>
                      </Box>
                    ) : filter.filtered === false ? (
                      ""
                    ) : (
                      <Box
                        key={filter.displayName}
                        className="each-filter-frame"
                      >
                        <Typography>
                          {/* {filter.displayName === "Merchant Reference Id" && (
                            <span
                              className={
                                merchantReferenceId
                                  ? "filter-label-text-bold"
                                  : "filter-label-text"
                              }
                            >
                              {filter.displayName}
                            </span>
                          )} */}

                          {/* {filter.displayName === "Processor Recon Id" && (
                            <span
                              className={
                                processorReconId
                                  ? "filter-label-text-bold"
                                  : "filter-label-text"
                              }
                            >
                              {filter.displayName}
                            </span>
                          )} */}

                          {/* {filter.displayName === "Processor Id" && (
                            <span
                              className={
                                processorId
                                  ? "filter-label-text-bold"
                                  : "filter-label-text"
                              }
                            >
                              {filter.displayName}
                            </span>
                          )} */}

                          {filter.displayName === "Status" && (
                            <span
                              className={
                                filters?.status?.length > 0
                                  ? "filter-label-text-bold"
                                  : "filter-label-text"
                              }
                            >{`${filter.displayName}${
                              filters[filter.filterOptions.queryParam]
                                ?.length && filter.filterOptions.options
                                ? `: ${
                                    filters[filter.filterOptions.queryParam]
                                      .length
                                  } of ${filter.filterOptions.options.length}`
                                : ""
                            }`}</span>
                          )}

                          {filter.filterOptions.queryParam ===
                            "paymentMethod" && (
                            <span
                              className={
                                filters?.paymentMethod?.length > 0
                                  ? "filter-label-text-bold"
                                  : "filter-label-text"
                              }
                            >{`${filter.displayName}${
                              filters[filter.filterOptions.queryParam]
                                ?.length && filter.filterOptions.options
                                ? `: ${
                                    filters[filter.filterOptions.queryParam]
                                      .length
                                  } of ${filter.filterOptions.options.length}`
                                : ""
                            }`}</span>
                          )}

                          {filter.filterOptions.queryParam ===
                            "processorCode" && (
                            <span
                              className={
                                filters?.processorCode?.length > 0
                                  ? "filter-label-text-bold"
                                  : "filter-label-text"
                              }
                            >{`${filter.displayName}${
                              filters[filter.filterOptions.queryParam]
                                ?.length && filter.filterOptions.options
                                ? `: ${
                                    filters[filter.filterOptions.queryParam]
                                      .length
                                  } of ${filter.filterOptions.options.length}`
                                : ""
                            }`}</span>
                          )}

                          {filter.displayName === "Currency Code" && (
                            <span
                              className={
                                filters?.currency?.length > 0
                                  ? "filter-label-text-bold"
                                  : "filter-label-text"
                              }
                            >{`${filter.displayName}${
                              filters[filter.filterOptions.queryParam]
                                ?.length && filter.filterOptions.options
                                ? `: ${
                                    filters[filter.filterOptions.queryParam]
                                      .length
                                  } of ${filter.filterOptions.options.length}`
                                : ""
                            }`}</span>
                          )}

                          {filter.displayName === "Transaction Type" && (
                            <span
                              className={
                                filters?.transactionType?.length > 0
                                  ? "filter-label-text-bold"
                                  : "filter-label-text"
                              }
                            >{`${filter.displayName}${
                              filters[filter.filterOptions.queryParam]
                                ?.length && filter.filterOptions.options
                                ? `: ${
                                    filters[filter.filterOptions.queryParam]
                                      .length
                                  } of ${filter.filterOptions.options.length}`
                                : ""
                            }`}</span>
                          )}

                          {filter.displayName === "Amount" && (
                            <span
                              className={
                                amount
                                  ? "filter-label-text-bold"
                                  : "filter-label-text"
                              }
                            >
                              {filter.displayName}
                            </span>
                          )}

                          {filter.displayName === "Date" && (
                            <span
                              className={
                                dateLabel
                                  ? "filter-label-text-bold"
                                  : "filter-label-text"
                              }
                            >
                              {filter.displayName}
                              {dateLabel &&
                              dateLabel &&
                              filters.transactionTime_from &&
                              filters.transactionTime_to
                                ? `: (${filters?.transactionTime_from?.slice(
                                    0,
                                    10
                                  )} To ${filters?.transactionTime_to?.slice(
                                    0,
                                    10
                                  )})`
                                : ""}
                            </span>
                          )}
                        </Typography>
                        <Box className="drop-down-container">
                          <div
                            className="click-dropdown"
                            onClick={(event) =>
                              setAnchorEl(
                                { index, currentTarget: event.currentTarget },
                                setClickedChipIndex(index),
                                setSearchTerm("")
                              )
                            }
                            onDelete={
                              hasFilters
                                ? () =>
                                    handleChipDelete(
                                      filter.filterOptions.queryParam,
                                      filter.filterOptions.queryParamInbound,
                                      filter.filterOptions.queryParamOutbound
                                    )
                                : null
                            }
                            // sx={{
                            //   backgroundColor: hasFilters(filter)
                            //     ? "black"
                            //     : "transparent",
                            //   color: hasFilters(filter) ? "white" : "black",
                            //   "& .MuiChip-deleteIcon": {
                            //     display: hasFilters(filter)
                            //       ? "inherit"
                            //       : "none",
                            //     color: "white", background:"plum"
                            //   },
                            // }}
                          >
                            <Typography className="placehoder-text">
                              {filter?.displayName === "Status" &&
                              statusLength > 0 ? (
                                <div
                                  style={{
                                    display: "flex",
                                  }}
                                >
                                  {filters?.status
                                    ?.slice(0, 3)
                                    .map((each, index) => {
                                      return (
                                        <div key={each}>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                              padding: "0px 5px",
                                              marginRight: "5px",
                                            }}
                                            className="chip-background"
                                            key={index}
                                            onClick={(e) => e.stopPropagation()}
                                          >
                                            <Typography
                                              sx={{
                                                fontSize: "10px",
                                                color: "black",
                                              }}
                                            >
                                              {each}
                                            </Typography>
                                            <IconButton
                                              onClick={(e) => {
                                                handleFilterCheckboxChange(
                                                  filter.filterOptions
                                                    .queryParam,
                                                  each
                                                );
                                                e.stopPropagation();
                                              }}
                                            >
                                              <XCircleIcon
                                                sx={{ fontSize: "15px" }}
                                              />
                                            </IconButton>
                                          </Box>
                                        </div>
                                      );
                                    })}
                                  <Typography
                                    variant="h5"
                                    sx={{ marginTop: "5px" }}
                                  >
                                    {statusLength > 3 && "..."}
                                  </Typography>
                                </div>
                              ) : (
                                filter?.displayName === "Status" &&
                                `Select  ${filter.displayName}`
                              )}

                              {filter.displayName === "Amount" &&
                                `Select  ${filter.displayName}`}
                              {filter.displayName === "Merchant Reference Id" &&
                                `Select  ${filter.displayName}`}
                              {filter.displayName === "Date" && getDate
                                ? filters.transactionTime_from?.slice(0, 10)
                                : filter.displayName === "Date" &&
                                  `Select  ${filter.displayName}`}
                              {filter.displayName === "Processor Recon Id" &&
                                `Select  ${filter.displayName}`}
                              {filter.displayName === "Processor Id" &&
                                `Select  ${filter.displayName}`}

                              {filter?.displayName === "Payment Type" &&
                              paymentMethodLength > 0 ? (
                                <div
                                  style={{
                                    display: "flex",
                                  }}
                                >
                                  {filters?.paymentMethod
                                    ?.slice(0, 3)
                                    .map((each, index) => {
                                      return (
                                        <div key={each}>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                              padding: "0px 5px",
                                              marginRight: "5px",
                                            }}
                                            className="chip-background"
                                            key={index}
                                            onClick={(e) => e.stopPropagation()}
                                          >
                                            <Typography
                                              sx={{
                                                fontSize: "10px",
                                                color: "black",
                                              }}
                                            >
                                              {each}
                                            </Typography>
                                            <IconButton
                                              onClick={(e) => {
                                                handleFilterCheckboxChange(
                                                  filter.filterOptions
                                                    .queryParam,
                                                  each
                                                );
                                                e.stopPropagation();
                                              }}
                                            >
                                              <XCircleIcon
                                                sx={{ fontSize: "15px" }}
                                              />
                                            </IconButton>
                                          </Box>
                                        </div>
                                      );
                                    })}
                                  <Typography
                                    variant="h5"
                                    sx={{ marginTop: "5px" }}
                                  >
                                    {paymentMethodLength > 3 && "..."}
                                  </Typography>
                                </div>
                              ) : (
                                filter?.displayName === "Payment Type" &&
                                `Select  ${filter.displayName}`
                              )}

                              {filter?.displayName === "Currency Code" &&
                              currencyLength > 0 ? (
                                <div
                                  style={{
                                    display: "flex",
                                  }}
                                >
                                  {filters?.currency
                                    ?.slice(0, 3)
                                    .map((each, index) => {
                                      return (
                                        <div key={each}>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                              padding: "0px 5px",
                                              marginRight: "5px",
                                            }}
                                            className="chip-background"
                                            key={index}
                                            onClick={(e) => e.stopPropagation()}
                                          >
                                            <Typography
                                              sx={{
                                                fontSize: "10px",
                                                color: "black",
                                              }}
                                            >
                                              {each}
                                            </Typography>
                                            <IconButton
                                              onClick={(e) => {
                                                handleFilterCheckboxChange(
                                                  filter.filterOptions
                                                    .queryParam,
                                                  each
                                                );
                                                e.stopPropagation();
                                              }}
                                            >
                                              <XCircleIcon
                                                sx={{ fontSize: "15px" }}
                                              />
                                            </IconButton>
                                          </Box>
                                        </div>
                                      );
                                    })}
                                  <Typography
                                    variant="h5"
                                    sx={{ marginTop: "5px" }}
                                  >
                                    {currencyLength > 3 && "..."}
                                  </Typography>
                                </div>
                              ) : (
                                filter?.displayName === "Currency Code" &&
                                `Select  ${filter.displayName}`
                              )}

                              {filter?.displayName === "Processor Code" &&
                              processorCodeLength > 0 ? (
                                <div
                                  style={{
                                    display: "flex",
                                  }}
                                >
                                  {filters?.processorCode
                                    ?.slice(0, 3)
                                    .map((each, index) => {
                                      return (
                                        <div key={each}>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                              padding: "0px 5px",
                                              marginRight: "5px",
                                            }}
                                            key={index}
                                            className="chip-background"
                                            onClick={(e) => e.stopPropagation()}
                                          >
                                            <Typography
                                              sx={{
                                                fontSize: "10px",
                                                color: "black",
                                              }}
                                            >
                                              {each}
                                            </Typography>
                                            <IconButton
                                              onClick={(e) => {
                                                handleFilterCheckboxChange(
                                                  filter.filterOptions
                                                    .queryParam,
                                                  each
                                                );
                                                e.stopPropagation();
                                              }}
                                            >
                                              <XCircleIcon
                                                sx={{ fontSize: "15px" }}
                                              />
                                            </IconButton>
                                          </Box>
                                        </div>
                                      );
                                    })}
                                  <Typography
                                    variant="h5"
                                    sx={{ marginTop: "5px" }}
                                  >
                                    {processorCodeLength > 3 && "..."}
                                  </Typography>
                                </div>
                              ) : (
                                filter?.displayName === "Processor Code" &&
                                `Select  ${filter.displayName}`
                              )}

                              {filter?.displayName === "Transaction Type" &&
                              transactionTypeLength > 0 ? (
                                <div
                                  style={{
                                    display: "flex",
                                  }}
                                >
                                  {filters?.transactionType
                                    ?.slice(0, 3)
                                    .map((each, index) => {
                                      return (
                                        <div key={each}>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                              padding: "0px 5px",
                                              marginRight: "5px",
                                            }}
                                            key={index}
                                            className="chip-background"
                                            onClick={(e) => e.stopPropagation()}
                                          >
                                            <Typography
                                              sx={{
                                                fontSize: "10px",
                                                color: "black",
                                              }}
                                            >
                                              {each}
                                            </Typography>
                                            <IconButton
                                              onClick={(e) => {
                                                handleFilterCheckboxChange(
                                                  filter.filterOptions
                                                    .queryParam,
                                                  each
                                                );
                                                e.stopPropagation();
                                              }}
                                            >
                                              <XCircleIcon
                                                sx={{ fontSize: "15px" }}
                                              />
                                            </IconButton>
                                          </Box>
                                        </div>
                                      );
                                    })}
                                  <Typography
                                    variant="h5"
                                    sx={{ marginTop: "5px" }}
                                  >
                                    {transactionTypeLength > 3 && "..."}
                                  </Typography>
                                </div>
                              ) : (
                                filter?.displayName === "Transaction Type" &&
                                `Select  ${filter.displayName}`
                              )}
                            </Typography>
                            {Boolean(anchorEl && anchorEl.index === index) ? (
                              <ExpandLessIcon className="cart-down-icon" />
                            ) : (
                              <CaretDown className="cart-down-icon" />
                            )}
                          </div>
                        </Box>

                        <Menu
                          className="pop-up-alignment"
                          anchorEl={
                            anchorEl && anchorEl.index === index
                              ? anchorEl.currentTarget
                              : null
                          }
                          open={Boolean(anchorEl && anchorEl.index === index)}
                          onClose={() => {
                            setAnchorEl(null);
                            setClickedChipIndex(null);
                            setFromButtonStatus(false);
                            setToButtonStatus(false);
                            setDate((prev) => ({
                              ...prev,
                              fromDate: "",
                              toDate: "",
                            }));
                            setTime((prev) => ({
                              ...prev,
                              fromTime: "",
                              toTime: "",
                            }));
                          }}
                        >
                          {filter.filterOptions.type === "INTEGER" && (
                            <div>
                              <MenuItem className="drop-down-menu-item">
                                <TextField
                                  key=""
                                  size="small"
                                  type="number"
                                  label="From"
                                  placeholder="From"
                                  fullWidth
                                  value={filters.from}
                                  onChange={(e) =>
                                    handleFilterChange(
                                      filter.filterOptions.queryParamOutbound,
                                      e.target.value
                                    )
                                  }
                                />
                              </MenuItem>
                              <MenuItem>
                                <TextField
                                  size="small"
                                  type="number"
                                  fullWidth
                                  label="To"
                                  placeholder="To"
                                  value={filters.to}
                                  onChange={(e) =>
                                    handleFilterChange(
                                      filter.filterOptions.queryParamOutbound,
                                      e.target.value
                                    )
                                  }
                                />
                              </MenuItem>
                            </div>
                          )}
                          {filter.filterOptions.type === "DATE" && (
                            <div style={{ padding: "0px 10px" }}>
                              <FunctionsProvider>
                                <Box
                                  sx={{
                                    display: "flex",
                                    gap: 2,
                                  }}
                                >
                                  <Today
                                    filter={filter}
                                    handleFilterChange={handleFilterChange}
                                    getDate={setGetDate}
                                  />
                                  <Yesterday
                                    filter={filter}
                                    handleFilterChange={handleFilterChange}
                                    getDate={setGetDate}
                                  />
                                  <Last7Days
                                    filter={filter}
                                    handleFilterChange={handleFilterChange}
                                    getDate={setGetDate}
                                  />
                                  <Last30Days
                                    filter={filter}
                                    handleFilterChange={handleFilterChange}
                                    getDate={setGetDate}
                                  />
                                </Box>
                              </FunctionsProvider>

                              <div style={{ display: "flex" }}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DemoContainer
                                    components={["DatePicker", "TimeClock"]}
                                  >
                                    <Box sx={{ display: "flex", gap: "10px" }}>
                                      <DemoItem>
                                        <h3 style={{ fontSize: "15px" }}>
                                          From Date
                                        </h3>
                                        <DatePicker
                                          sx={{
                                            height: "40px",
                                            "& .MuiInputBase-root": {
                                              height: "100%",
                                            },
                                          }}
                                          onChange={(newFromDate) =>
                                            handleFromDateChange(
                                              convertFromDate(newFromDate.$d)
                                            )
                                          }
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Text"
                                            />
                                          )}
                                        />

                                        <DemoItem>
                                          <StaticTimePicker
                                            onChange={(time) => {
                                              handleFromTimeChange(
                                                convertFromTime(time),
                                                filter
                                              );
                                            }}
                                            value={toTime}
                                          />
                                        </DemoItem>
                                        {/* <Button
                                        disabled={fromButtonStatus}
                                        variant="outlined"
                                        onClick={() =>
                                          SelectFromTime("click", filter)
                                        }
                                      >
                                        Ok
                                      </Button> */}
                                      </DemoItem>
                                      <DemoItem>
                                        <h3 style={{ fontSize: "15px" }}>
                                          To Date
                                        </h3>

                                        <DatePicker
                                          sx={{
                                            height: "40px",
                                            "& .MuiInputBase-root": {
                                              height: "100%",
                                            },
                                          }}
                                          onChange={(newFromDate) =>
                                            handleToDateChange(
                                              convertToDate(newFromDate.$d)
                                            )
                                          }
                                          renderInput={(params) => (
                                            <TextField
                                              sx={{}}
                                              {...params}
                                              value=""
                                              placeholder="Text"
                                            />
                                          )}
                                        />

                                        <DemoItem>
                                          <StaticTimePicker
                                            onChange={(time) => {
                                              handleToTimeChange(
                                                convertToTime(time),
                                                filter
                                              );
                                            }}
                                            value={toTime}
                                          />
                                        </DemoItem>
                                        {/* <Button
                                        disabled={toButtonStatus}
                                        variant="outlined"
                                        onClick={() =>
                                          selectToTime("click", filter)
                                        }
                                      >
                                        Ok
                                      </Button> */}
                                      </DemoItem>
                                    </Box>
                                  </DemoContainer>
                                </LocalizationProvider>
                              </div>
                            </div>
                          )}
                          {filter.filterOptions.type === "ENUM" && (
                            <MenuItem className={paymentTypePopupHeight}>
                              <TextField
                                type="search"
                                size="small"
                                label="Search"
                                value={searchTerm}
                                fullWidth
                                onChange={(e) => {
                                  setSearchTerm(e.target.value);
                                }}
                                onKeyDown={(e) => e.stopPropagation()}
                              />

                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        filters[
                                          filter?.filterOptions.queryParam
                                        ]?.length ===
                                        filter?.filterOptions.options?.length
                                      }
                                      onChange={(e) => {
                                        handleSelectAllOptions(
                                          filter?.filterOptions.queryParam,
                                          filter?.filterOptions.options
                                        );
                                        e.stopPropagation();
                                      }}
                                    />
                                  }
                                  label="Select All"
                                />
                                <hr
                                  style={{
                                    background: "grey",
                                    height: "1px",
                                    width: "100%",
                                  }}
                                />

                                {filter.filterOptions.options
                                  .filter((option) =>
                                    option
                                      .toLowerCase()
                                      .includes(searchTerm.toLowerCase())
                                  )
                                  .map((option) => {
                                    return (
                                      <FormControlLabel
                                        key={option}
                                        control={
                                          <Checkbox
                                            checked={
                                              filters[
                                                filter.filterOptions.queryParam
                                              ]?.length ===
                                              filter.filterOptions.options
                                                ?.length
                                                ? true
                                                : (
                                                    selectedOptionsMap[
                                                      filter.filterOptions
                                                        .queryParam
                                                    ] || []
                                                  )?.includes(option)
                                            }
                                            onChange={(e) => {
                                              handleFilterCheckboxChange(
                                                filter.filterOptions.queryParam,
                                                option
                                              );
                                              e.stopPropagation();
                                            }}
                                            value={option}
                                          />
                                        }
                                        label={option}
                                      />
                                    );
                                  })}
                              </FormGroup>
                            </MenuItem>
                          )}
                          {filter.filterOptions.type !== "INTEGER" &&
                            filter.filterOptions.type !== "ENUM" &&
                            filter.filterOptions.type !== "DATE" && (
                              <MenuItem className="drop-down-menu-item">
                                <TextField
                                  size="small"
                                  type="search"
                                  fullWidth
                                  label={filter.displayName}
                                  placeholder={`Enter ${filter.displayName}`}
                                  value={
                                    filters[filter.filterOptions.queryParam]
                                  }
                                  onChange={(e) =>
                                    handleFilterChange(
                                      filter.filterOptions.queryParam,
                                      e.target.value
                                    )
                                  }
                                />
                              </MenuItem>
                            )}
                          <MenuItem
                            sx={{
                              display: "flex",
                              gap: "10px",
                              justifyContent: "space-between",
                            }}
                          >
                            {filter.filterOptions.type === "STRING" ? (
                              ""
                            ) : (
                              <>
                                <Button
                                  size="small"
                                  variant="outlined"
                                  color="inherit"
                                  onClick={() => {
                                    resetFilters(
                                      filter.filterOptions.queryParam
                                    );
                                    // resetData();
                                    setFromButtonStatus(false);
                                    setToButtonStatus(false);
                                    setDate((prev) => ({
                                      ...prev,
                                      fromDate: "",
                                      toDate: "",
                                    }));
                                    setTime((prev) => ({
                                      ...prev,
                                      fromTime: "",
                                      toTime: "",
                                    }));
                                    setFilters([]);
                                    setDateLabel(false);
                                    setGetDate(false);

                                    filter.displayName ===
                                      "Merchant Reference Id" &&
                                      setMerchantReferenceId(false);
                                    filter.displayName === "Amount" &&
                                      setAmount(false);
                                    filter.displayName === "Date" &&
                                      setDateLabel(false);
                                    filter.displayName ===
                                      "Processor Recon Id" &&
                                      setProcessorReconId(false);
                                    filter.displayName === "Processor Id" &&
                                      setProcessorId(false);
                                  }}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  // disabled={
                                  //   filter.filterOptions.type === "DATE"
                                  //     ? !dateApplyButtonStatus
                                  //     : false
                                  // }
                                  size="small"
                                  variant="outlined"
                                  color="inherit"
                                  onClick={() => {
                                    applyfilters(filter);
                                    setFromButtonStatus(false);
                                    setToButtonStatus(false);
                                    setDate((prev) => ({
                                      ...prev,
                                      fromDate: "",
                                      toDate: "",
                                    }));
                                    setTime((prev) => ({
                                      ...prev,
                                      fromTime: "",
                                      toTime: "",
                                    }));
                                    reloadFilters();
                                    filter.displayName ===
                                      "Merchant Reference Id" &&
                                      setMerchantReferenceId(true);
                                    filter.displayName === "Amount" &&
                                      setAmount(true);
                                    filter.displayName === "Date" &&
                                      setDateLabel(true);
                                    filter.displayName ===
                                      "Processor Recon Id" &&
                                      setProcessorReconId(true);
                                    filter.displayName === "Processor Id" &&
                                      setProcessorId(true);
                                  }}
                                >
                                  Ok
                                </Button>
                              </>
                            )}
                          </MenuItem>
                        </Menu>
                      </Box>
                    );
                  })}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <Box>
                    <Tooltip title="Cancel">
                      <Button
                        variant="outlined"
                        className="cancel-btn-text"
                        startIcon={<XCircleIcon />}
                        onClick={() => {
                          setFilters([]);
                          setSelectedOptionsMap([]);
                          fetchTransactionData();
                          setGetDate(false);
                          setDateLabel(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </Tooltip>
                    <Tooltip title="Apply">
                      <Button
                        variant="contained"
                        className="save-btn-text"
                        startIcon={<SaveIcon />}
                        onClick={() => {
                          reloadFilters();
                          setDateLabel(true);
                        }}
                      >
                        Apply
                      </Button>
                    </Tooltip>
                  </Box>
                </Box>
              </div>
            )}

            {/*-------------------------------------Filter-Changes---------------------------------------------*/}

            {warning && (
              <Alert severity="error">
                Something went wrong while fetching the transactions
              </Alert>
            )}

            <TableContainer component={Paper}>
              <Table
                sx={{
                  minWidth: 700,
                }}
                aria-label="customized table"
              >
                <TableHead
                  sx={{
                    background: " #F5F7FA",
                  }}
                >
                  <TableRow>
                    {data.ListOfShowingColumns.map((columnKey) => {
                      const getWidth = () => {
                        if (
                          data.transactionsMap[columnKey].displayName ===
                          "Merchant Reference Id"
                        ) {
                          return "180px";
                        } else if (
                          data.transactionsMap[columnKey].displayName ===
                          "Transaction Type"
                        ) {
                          return "140px";
                        } else if (
                          data.transactionsMap[columnKey].displayName === "Id"
                        ) {
                          return "140px";
                        } else if (
                          data.transactionsMap[columnKey].displayName ===
                          "Status"
                        ) {
                          return "80px";
                        } else if (
                          data.transactionsMap[columnKey].displayName ===
                          "Payment Type"
                        ) {
                          return "120px";
                        } else if (
                          data.transactionsMap[columnKey].displayName ===
                          "Amount"
                        ) {
                          return "80px";
                        } else if (
                          data.transactionsMap[columnKey].displayName ===
                          "Currency Code"
                        ) {
                          return "150px";
                        } else if (
                          data.transactionsMap[columnKey].displayName ===
                          "Processor Code"
                        ) {
                          return "140px";
                        } else if (
                          data.transactionsMap[columnKey].displayName === "Date"
                        ) {
                          return "100px";
                        } else if (
                          data.transactionsMap[columnKey].displayName ===
                          "Processor Recon Id"
                        ) {
                          return "160px";
                        } else if (
                          data.transactionsMap[columnKey].displayName ===
                          "Processor Id"
                        ) {
                          return "120px";
                        }
                      };

                      return (
                        <>
                          <StyledTableCell
                            key={columnKey}
                            className="table-styled-cell"
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                width: getWidth,
                              }}
                            >
                              <Typography className="table-header-text">
                                {data.transactionsMap[columnKey].displayName}
                              </Typography>
                              <Box
                                sx={{
                                  height: "20px",
                                  width: "20px",
                                  marginLeft: "5px",
                                }}
                              >
                                <ExpandUpdownFill />
                              </Box>
                            </Box>
                          </StyledTableCell>
                        </>
                      );
                    })}
                    <StyledTableCell className="table-styled-cell">
                      {/* Action */}
                    </StyledTableCell>
                    <FormControl>
                      <Select
                        labelId="select-columns-label"
                        value=""
                        IconComponent={AddIcon}
                      >
                        {Object.keys(data.transactionsMap).map((columnKey) => (
                          <MenuItem key={columnKey} value={columnKey}>
                            <Checkbox
                              checked={checkedColumns.includes(columnKey)}
                              onChange={handleCheckboxChange(columnKey)}
                            />
                            {data.transactionsMap[columnKey].displayName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </TableRow>
                </TableHead>

                {isLoading ? (
                  <TableBody>
                    <tr>
                      <td
                        colSpan={data.ListOfShowingColumns.length}
                        style={{ textAlign: "center" }}
                      >
                        <DNA
                          visible={true}
                          height="80"
                          width="80"
                          ariaLabel="dna-loading"
                          wrapperStyle={{}}
                          wrapperClass="dna-wrapper"
                        />
                      </td>
                    </tr>
                  </TableBody>
                ) : (
                  <>
                    <TableBody>
                      <>
                        {filterData?.length > 0 ? (
                          filterData?.map((item, key) => {
                            return (
                              <>
                                <StyledTableRow key={key}>
                                  {data.ListOfShowingColumns.map(
                                    (columnKey) => (
                                      <StyledTableCell
                                        key={columnKey}
                                        sx={{ background: "#FFF" }}
                                      >
                                        <Typography
                                          className={`table-data-text`}
                                        >
                                          {renderTableCell(
                                            item,
                                            columnKey,
                                            data
                                          )}
                                        </Typography>
                                      </StyledTableCell>
                                    )
                                  )}
                                  <StyledTableCell sx={{ background: "#FFF" }}>
                                    <div>
                                      <Popup
                                        trigger={
                                          <Box>
                                            <IconButton>
                                              <DotsThreeVertical />
                                            </IconButton>
                                          </Box>
                                        }
                                        position="bottom left"
                                        contentStyle={{ width: "90px" }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            padding: "8px 0px",
                                            gap: "4px",
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            handleLinkClick(
                                              item.id,
                                              item.transactionType
                                            )
                                          }
                                        >
                                          <Box
                                            sx={{
                                              height: "20px",
                                              width: "20px",
                                              marginRight: "3px",
                                            }}
                                          >
                                            <FileText />
                                          </Box>
                                          <Typography className="details-text">
                                            Details
                                          </Typography>
                                        </Box>
                                      </Popup>
                                    </div>
                                  </StyledTableCell>
                                </StyledTableRow>
                              </>
                            );
                          })
                        ) : (
                          <Typography className="not-matched-text">
                            No Matching Data Found
                          </Typography>
                        )}
                      </>
                    </TableBody>
                  </>
                )}
              </Table>
            </TableContainer>
            <TableFooter
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                paddingRight: "35px",
              }}
            >
              <tr>
                <td colSpan={data.ListOfShowingColumns.length}>
                  <div>
                    <Pagination
                      count={10}
                      page={page}
                      onChange={handleChangePage}
                      variant="outlined"
                      shape="rounded"
                    />
                  </div>
                </td>
              </tr>
            </TableFooter>
          </>
        ) : (
          <TransactionDetails
            id={clickedLinkId}
            transactionType={selectedTransactionType}
            handlingDetailsClose={handleDetailsClose}
            transactionsData={transactions}
          />
        )}
      </div>
    </FunctionsProvider>
  );
}

function getFirstNonNullValue(item, path) {
  const fields = path.split(",").map((field) => field.trim());
  for (const field of fields) {
    const value = data.getColumnValue(item, field);
    if (value !== null && value !== undefined) {
      return value;
    }
  }
  // If all fields are null or undefined, return an empty string or any default value you prefer
  return "";
}

export default Transactions;
