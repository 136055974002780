import { URL, PROCESSORLIST_ENDPOINT } from "constants/Constants";
import { httpClient } from "utils/HttpClient";

import React, { createContext, useContext, useState, useEffect } from "react";
const ProcessorServiceContext = createContext();

export const ProcessorServiceProvider = ({ children }) => {
  const [processorData, setProcessorData] = useState();

  useEffect(() => {
    httpClient
      .fetchData(`${URL}${PROCESSORLIST_ENDPOINT}`, "GET")
      .then((data) => {
        // console.log(data, "processorData- test");
        setProcessorData(data);
      });
  }, []);

  return (
    <ProcessorServiceContext.Provider value={{ processorData }}>
      {children}
    </ProcessorServiceContext.Provider>
  );
};

export const useProcessorData = () => useContext(ProcessorServiceContext);
