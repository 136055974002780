import React from "react";
import "./AddPaymentProcessorModal.css";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import Wallet from "../Icons/Wallet";
import Close from "../Icons/Close";
import Radio from "../Icons/Radio";
import SelectedRadio from "../Icons/SelectedRadio";
import { URL, MERCHANT_ID, PROCESSOR_ENDPOINT } from "constants/Constants";
import { httpClient } from "utils/HttpClient";
import { processors } from "utils/processors";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "20px",
  width: "648px",
};

const Item = ({ selected, item }) => {
  const title = (
    item?.processorCode?.charAt(0)?.toUpperCase() +
    item?.processorCode?.slice(1)?.toLocaleLowerCase()
  ).replaceAll("_", " ");

  return (
    <div
      className={`payment-processor-item payment-processor-item-${
        selected ? "selected" : "not-selected"
      }`}
    >
      <div className="payment-processor-item-selection-container">
        <div className="payment-processor-item-title-container">
          <div className="payment-processor-item-img-container">
            <img
              loading="lazy"
              src={item?.logo_url}
              className="payment-processor-item-img"
            />
          </div>
          <div className="payment-processor-item-title">{title}</div>
        </div>
        <div className="payment-processor-item-radio-container">
          {selected ? <Radio /> : <SelectedRadio />}
        </div>
      </div>
      <div className="payment-processor-item-subtitle">
        Accepts Bank transfer, wallets and more.
      </div>
    </div>
  );
};

const AddPaymentProcessorModal = ({
  open,
  setOpen,
  addPayment,
  processorData,
  setSelectedItem,
}) => {
  const [list, setList] = React.useState();
  const [payemntList, setPaymentList] = React.useState();
  const [selected, setSelected] = React.useState("");

  React.useEffect(() => {
    fetchSettingsData();
    fetchListData();
  }, [processorData]);

  function transformPaymentOptions(paymentOptions) {
    const transformed = {};

    for (const [key, value] of Object.entries(paymentOptions)) {
      transformed[key] = {
        currencies: [],
        defaultOption: false,
        status: "DISABLED",
      };
    }

    return transformed;
  }

  React.useEffect(() => {
    if (selected) {
      const selectedData = payemntList.find(
        (item) => item.code === selected
      ).paymentMethods;
      setSelectedItem({
        id: null,
        processorCode: selected,
        status: "ACTIVE",
        processorConfigurations: {
          merchantId: "",
          clientKey: "",
          serverKey: "",
        },
        paymentTypes: transformPaymentOptions(selectedData),
      });
    }
  }, [selected]);

  const fetchListData = () => {
    httpClient
      .fetchData(
        `${URL}${PROCESSOR_ENDPOINT}`,
        
        "GET"
      )
      .then((settings) => {
        setPaymentList(
          settings.filter((setting) => {
            return !processorData?.find(
              (item) =>
                item.processorCode.toLowerCase() === setting.code.toLowerCase()
            );
          })
        );
        console.log(settings);
      });
  };

  const fetchSettingsData = () => {
    const processorList = processors.filter((setting) => {
      return !processorData?.find(
        (item) =>
          item.processorCode.toLowerCase() ===
          setting.processorCode.toLowerCase()
      );
    });
    setList(processorList);
  };

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Box sx={style}>
        <div className="modal-header">
          <span className="modal-header-container">
            <div className="modal-header-img-container">
              <Wallet />
            </div>
            <div className="modal-header-title-container">
              <div className="modal-header-title">Add a Payment Processor</div>
              <div className="modal-header-subtitle">
                Select the payment processor that you want to add.
              </div>
            </div>
          </span>
          <span className="modal-header-close" onClick={() => setOpen(false)}>
            <Close />
          </span>
        </div>
        <div className="modal-body">
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              {(list || []).map((item) => (
                <Grid item xs={6} key={item.processorCode}>
                  <div onClick={() => setSelected(item.processorCode)}>
                    <Item
                      selected={item.processorCode === selected}
                      item={item}
                    />
                  </div>
                </Grid>
              ))}
            </Grid>
          </Box>
        </div>

        <div className="modal-footer">
          <div className="modal-footer-container">
            <div className="modal-footer-cancel" onClick={() => setOpen(false)}>
              Cancel
            </div>
            <div className="modal-footer-ok" onClick={() => addPayment()}>
              Add Processor
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default AddPaymentProcessorModal;
