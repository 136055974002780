import React, { createContext, useContext, useState } from "react";
const FunctionsContext = createContext();

export const FunctionsProvider = ({ children }) => {
  const [activeButton, setActiveButton] = useState("");
  const [todayDate, setTodayDate] = useState(false);

  const handleButtonClick = (value) => {
    setActiveButton(value);
  };

  const handleTodayDate = (todayValue) => {
    setTodayDate(todayValue);
  };

  return (
    <FunctionsContext.Provider
      value={{ handleButtonClick, activeButton, handleTodayDate, todayDate }}
    >
      {children}
    </FunctionsContext.Provider>
  );
};

export const useFunctions = () => useContext(FunctionsContext);
