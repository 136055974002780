import React, { useState, useEffect } from "react";
import {
  Checkbox,
  TextField,
  Grid,
  Box,
  Typography,
  FormControlLabel,
} from "@mui/material";

import LoadingButton from "@mui/lab/LoadingButton";
import "./Webhooks.css";
import webhookEvents from "../../../utils/WebhookEvents";
import { httpClient } from "utils/HttpClient";
import {
  URL,
  WEBHOOK_SETTINGS_ENDPOINT,
  MERCHANT_ID,
} from "constants/Constants";
import Logout from "Logout";
import SaveIcon from "../../Icons/FloppyDisk";
function Webhooks() {
  const [webhookUrl, setWebhookUrl] = useState("");
  const [webhookProperties, setWebhookProperties] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isValidUrl, setIsValidUrl] = useState(false);
  const [saveButtonStatus, setSaveButtonStatus] = useState(true);

  const errorMessage = webhookUrl && webhookUrl.startsWith("https://");
  console.log(errorMessage, "message");

  console.log(isValidUrl, "url");

  useEffect(() => {
    if (typeof webhookUrl === "string" && webhookUrl.startsWith("https://")) {
      setIsValidUrl(true);
      setSaveButtonStatus(false);
    } else {
      setIsValidUrl(false);
    }
  }, [webhookUrl]);

  useEffect(() => {
    fetchWebhookEventsData();
  }, []);

  const fetchWebhookEventsData = async () => {
    try {
      const data = await httpClient.fetchData(
        `${URL}${WEBHOOK_SETTINGS_ENDPOINT}`,
        "GET"
      );
      console.log(data);
      setWebhookUrl(data?.webhookUrl);
      setWebhookProperties(data?.webhookProperties || {});
    } catch (error) {
      console.error("Error fetching webhook data:", error);
    }
  };

  const handleSave = async () => {
    // if (isValidUrl === true) {
    console.log("Save Button");
    try {
      setIsLoading(true);
      const response = await httpClient.fetchData(
        `${URL}${WEBHOOK_SETTINGS_ENDPOINT}`,
        "POST",
        {
          webhookUrl,
          webhookProperties,
        }
      );
      if (response.ok) {
        console.log("get response");
        return response.json();
      } else {
        // Check if the status code is 401
        if (response.status === 401) {
          Logout();
        } else {
          console.log("Failed saving data:", response.status);
        }
      }
    } catch (error) {
      console.error("Error saving webhook data:", error);
    } finally {
      setIsLoading(false);
    }

    // }
  };

  const handleChange = (eventType, category) => {
    setWebhookProperties((prevProperties) => ({
      ...prevProperties,
      [eventType]: !prevProperties[eventType],
    }));
  };

  return (
    <div>
      <Grid>
        <Box className="webhook-and-search-url-container">
          <Grid className="content-label">
            <Typography className="webhook-url-text">Webhook URL</Typography>
          </Grid>
          <Box sx={{ width: "100%" }}>
            <Grid className="search-layout-cont">
              <TextField
                size="small"
                sx={{
                  "& fieldset": { border: "none" },
                }}
                className="search-bar"
                fullWidth
                value={webhookUrl}
                onChange={(e) => setWebhookUrl(e.target.value)}
                placeholder="Add URL"
              />
            </Grid>
            <p
              className={
                errorMessage
                  ? "hide-text"
                  : webhookUrl !== ""
                  ? "error-message"
                  : "hide-text"
              }
            >
              Enter Correct Url
            </p>
          </Box>
        </Box>

        <Box className="hr-line-label-container">
          <div className="hr-line"></div>
        </Box>

        {Object.entries(webhookEvents.webhookEvents).map(
          ([category, events]) => (
            <Grid item xs={12} key={category}>
              <Box className="payments-border-container">
                <Grid>
                  <Typography className="payment-status-text">
                    {category}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <div className="payment-status-row-container">
                    {events.map((eventType) => (
                      <div key={eventType} className="each-checkbox-container">
                        <FormControlLabel
                          className="checkbox-text"
                          control={
                            <Checkbox
                              checked={webhookProperties[eventType] || false}
                              onChange={() => handleChange(eventType, category)}
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  fontSize: 22,
                                },
                                color: "#E1E4EA",
                                "&.Mui-checked": {
                                  color: "primary",
                                },
                                "& .MuiCheckbox-root": {
                                  borderRadius: "8px",
                                },
                              }}
                            />
                          }
                          label={eventType}
                        />
                      </div>
                    ))}
                  </div>
                </Grid>
              </Box>
            </Grid>
          )
        )}
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <LoadingButton
            loading={isLoading}
            sx={{ marginTop: "10px" }}
            variant="contained"
            onClick={handleSave}
            className="save-btn-text"
            startIcon={<SaveIcon />}
            disabled={saveButtonStatus}
          >
            Save
          </LoadingButton>
        </Grid>
      </Grid>
    </div>
  );
}

export default Webhooks;
