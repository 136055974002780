import React, { useState } from "react";
import Box from "@mui/material/Box";
//import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { Select } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import { Button, Menu, MenuItem } from "@mui/material";

function ElementDropdown({
  data,
  dropdownData,
  onChangeCallback,
  type,
  disabled,
  value,
  error,
  errorMessage,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (e) => {
    data = { ...data, [type]: e.target.value };
    onChangeCallback(data);
  };

  return (
    <Box sx={{ display: "block" }}>
      <Box sx={{ minWidth: 180 }}>
        <FormControl fullWidth size="small">
          <InputLabel
            sx={{ display: disabled ? "none" : "" }}
            id={disabled ? "demo-select-small-label" : ""}
          >
            Select
          </InputLabel>
          <Select
            style={{ borderRadius: "8px" }}
            value={value}
            disabled={disabled}
            onChange={handleChange}
            error={error && !value}
            label={disabled ? "" : "Select"}
          >
            {dropdownData?.map((data, idx) => {
              return (
                <MenuItem
                  onClick={handleClose}
                  style={{ padding: "5px 30px 5px 10px" }}
                  value={data.value || data}
                >
                  {data.label || data}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>

      {/* <Button
        variant="contained"
        color="primary"
        onClick={handleClick}
        disabled={disabled}
        error={error && !value}
        value={value}
      >
        Open Menu
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {dropdownData?.map((data, idx) => {
          return (
            <MenuItem
              onClick={handleClose}
              style={{ padding: "5px 30px 5px 10px" }}
              value={data.value || data}
            >
              {data.label || data}
            </MenuItem>
          );
        })}
      </Menu> */}

      {error && !value ? (
        <FormHelperText error>{errorMessage}</FormHelperText>
      ) : null}
    </Box>
  );
}

export default ElementDropdown;
