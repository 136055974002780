import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { MenuItems } from "utils/MenuItems";
import "./Menu.css";
import Synergy from "components/Icons/Synergy";

function Menu(props) {
  const [toggleExpand, setToggleExpand] = useState(false);

  return (
    <div className="hamburger-menu-container">
      <div
        onClick={() => setToggleExpand(!toggleExpand)}
        className={`hamburger-menu-header ${
          toggleExpand ? "expanded" : "contracted"
        }`}
      >
        {toggleExpand ? (
          <div className="hamburger-menu-header-container">
            <Synergy className={"hamburger-menu-icon"} />
            <span>Payorch</span>
          </div>
        ) : (
          <MenuIcon className={"hamburger-menu-icon"} />
        )}
      </div>
      <div className="menu-container-body" >
        <ul>
          {MenuItems.map((items, key) => {
            if (items.name !== "Logout") {
              return (
                <li
                  onClick={() => props.onClick(items.component, items.name)}
                  key={key}
                >
                  {items.icon}
                  {toggleExpand && items.name}
                </li>
              );
            } else {
              return (
                <footer className={toggleExpand ? "expanded" : "contracted"}>
                  <ul>
                    <li
                      onClick={() => props.onClick(items.component, items.name)}
                      key={key}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {toggleExpand && "Sign Out"}
                      {items.icon}
                    </li>
                  </ul>
                </footer>
              );
            }
          })}
        </ul>
      </div>
    </div>
  );
}

export default Menu;
